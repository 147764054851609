import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const WinterWarmer = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/winter-warmer/entrance.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/winter-warmer/inside-1.jpg`} alt={item} {...imageProps}/>
            case 3:
                return <StaticImage src={`../../images/brand/winter-warmer/winter-3.jpg`} alt={item} {...imageProps}/>
            case 4:
                return <StaticImage src={`../../images/brand/winter-warmer/inside-3.jpg`} alt={item} {...imageProps}/>
            case 5:
                return <StaticImage src={`../../images/brand/winter-warmer/other-1.JPG`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/winter-warmer/other-2.JPG`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "",
        "",
        "",
        "",
        "",
        "",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Winter Warmers" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-32 mr-5">
                            <StaticImage src="../../images/brand/brand-1.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Winter Warmers</h2>
                        <p className="font-light text-gray-600">8am – 10.30pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 04-291 9848</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">          
                        Winter Warmers Coffee & Tea House is a locally-owned chain of English-style tea house. There are currently 9 outlets in total, all located in shopping malls across Penang and Taiping. Winter Warmers is founded in 2000 by a tea enthusiast, who has an obsession for tea set collections. Winter Warmers adopted the closed concept that provides a peace and tranquil atmosphere for the customers to relax and enjoy the original English teas. They are also known for their signature afternoon tea set which comes with homemade desserts and savoury delights. Italian coffees and English teas are the main attractions that eloquently elaborative into details made by serving the finest range of teas, herbal or black be it your choice, and exquisite sets of coffees to kick start your taste bud. 
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-7-winter.jpg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default WinterWarmer



